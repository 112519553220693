body {
    background: #353844;
    /*    background: linear-gradient( 180deg, rgba(42, 23, 104, 1) 40%, rgba(235, 143, 117, 1) 100%);*/
    color: white;
}

.usdc {
    background-color: #0eb8a7;
    position: absolute;
    top: -2px;
    left: -2px;
    width: 28px;
    height: 28px;
    border: solid 2px #0eb8a7;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.strange {
    background-color: #0eb8a7;
    position: absolute;
    top: -2px;
    left: -2px;
    width: 28px;
    height: 28px;
    border: solid 2px #0eb8a7;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.treshul {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 5;
  position: absolute;
}

.chrishchan {
    background-color: #0eb8a7;
    position: absolute;
    top: -2px;
    left: -2px;
    width: 28px;
    height: 28px;
    border: solid 2px #0eb8a7;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.op {
    background-color: #0eb8a7;
    position: absolute;
    top: -2px;
    left: -2px;
    width: 28px;
    height: 28px;
    border: solid 2px #0eb8a7;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.cring {
    background-color: #121926;
    position: absolute;
    top: -2px;
    left: -2px;
    width: 28px;
    height: 28px;
    border: solid 2px #0eb8a7;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.bg-fill {
    background-color: #0eb8a7;
}

.img-setting {
  position: absolute;
  top: 17%;
  left: 38%;
  z-index: 7;
}

.inactive-tab {
    background-color: #0eb8a7;
}

.card-bg {
    background-color: #0eb8a7;
}

.add-shadow {
    box-shadow: 3px 3px 4px #7f858585;
}

.text-darken {
    color: #0eb8a7;
}

.fullscrn {
    height: 100vh;
}

.modal {
    position: absolute;
    top: 10vh;
    bottom: 10vh;
    left: 10vw;
    right: 10vw;
    background-color: #fff;
    visibility: hidden;
    border: none;
}

.modal1 {
    position: relative;
    width: 99%;
    height: 98%;
    margin: 0.5%;
    background-color: #353844;
    visibility: hidden;
    display: grid;
    grid-template-rows: 10% 1fr 1fr 1fr 1fr;
    grid-row-gap: 15px;
    border: none;
}

.titolo p {
    display: inline;
    font-size: 40px;
    vertical-align: middle;
}

.smallIMGtitolo {
    height: 80%;
    display: inline;
    margin: 18px 20px 0 20px;
}

.copyBTN {
    background-color: white;
    height: 30px;
    width: 30px;
    margin: 4px 0 0 10px;
    vertical-align: middle;
    border-radius: 8px;
}

.copyIMG {
    height: 70%;
    width: 70%;
    margin: 0 0 0 15%;
}

.modalrows {
    display: grid;
    grid-template-columns: 10% 1fr;
    background-color: rgb(83, 88, 105);
}

.dexicons {
    height: 80%;
    width: contain;
    display: inline;
    margin: 10% 20px 0 20px;
}

.dexInfo {
    display: grid;
    grid-template-rows: 20% 60% 10%;
}

.dexTitle {
    font-size: 32px;
    display: inline;
    margin: 7px 0 0 5px;
}

.dexbio {
    margin: 7px 0 0 5px;
    width: 15%;
    line-height: 18px;
}

.dexlink {
    margin: -3px 0 0 5px;
    width: 15%;
    line-height: 24px;
    font-size: 20px;
    text-decoration: underline;
    color: #33c0ff;
}
