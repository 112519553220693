@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: DMSans-Bold;
  src: url('../fontfamily/DMSans-Bold.ttf');
}

@font-face {
  font-family: DMSans-Medium;
  src: url('../fontfamily/DMSans-Medium.ttf');
}

@font-face {
  font-family: DMSans-Regular;
  src: url('../fontfamily/DMSans-Regular.ttf');
}

.f-f-r {
  font-family: DMSans-Regular;
}

.f-f-m {
  font-family: DMSans-Medium;
}

.f-f-b {
  font-family: DMSans-Bold;
}

.no-scrollbar::-webkit-scrollbar {
  width: 0 !important;
  display: none !important;
}

.bg-grey {
    background-color: rgb(205, 205, 215);
}